/* Start swiper style */

.dashed-hr {
  border: none;
  border-top: 2px dashed #000;
  margin: 10px 0px;
}

.swiper {
  width: 100%;
  height: fit-content;
  padding-bottom: 35px;
}

.swiper-slide {
  font-size: 18px;
  margin-right: 15px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-pagination-bullet {
  background-color: #24b0a2;
}

.swiper-button-next,
.swiper-button-prev {
  color: #24b0a2;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: white;

  width: 10px;
  height: 10px;
}

.swiper-button-next:after {
  margin-right: 15px;
}

.modal .sub-title {
  font-weight: 300;
  color: gray;
}
.modal h3 {
  color: rgb(97, 97, 97);
}
.modal h5 {
  font-weight: 300;
  color: gray;
}
.modal h6 {
  color: gray;
}
.modal h2 {
  color: #24b0a2;
  font-weight: 800;
}

.modal .title-ballance {
  color: #24b0a2;
  font-size: 3.5rem;
}

.modal .title-ballance::before {
  content: "Rp ";
  font-weight: 400;
  font-size: 15px;
  vertical-align: top;
}

.modal .btn-payment:hover {
  border: 1px solid #24b0a2;
  background-color: #24b0a2;
}

.modal .btn-payment:active {
  border: 1px solid #24b0a29b !important;
  background-color: #24b0a29b !important;
}

.modal .col-modal {
  padding: 20px;
}
.modal .btn-payment {
  /* width: 90%; */
  /* margin: 5%; */
  margin-bottom: 15px;
  background-color: transparent;
  border: 1px solid #24b0a2;
  color: #24b0a2;
}

.modal .btn-time:hover {
  background-color: rgba(128, 128, 128, 0.241);
  border: 1px solid rgba(128, 128, 128, 0.241);
}

.modal .btn-time {
  border: 1px solid #24b0a2;
  color: #24b0a2;
  background-color: transparent;
}

.modal .btn-time:focus {
  border: 1px solid #24b0a2;
  color: white;
  background-color: #24b0a2;
}

.modal .btn-time:active {
  border: 1px solid #24b0a2 !important;
  color: white;
  background-color: #24b0a2 !important;
}

.modal .input-group-prepend .btn-time:active {
  border: 1px solid #24b0a29b !important;
  color: white;
  background-color: #24b0a29b !important;
}

.modal .button-affiliate {
  border: 1px solid #24b0a2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  color: white;
  background-color: #24b0a2;
}

.modal .button-affiliate:hover {
  border: 1px solid #24b0a2 !important;
  background-color: white !important;
  color: #24b0a2;
}

.modal .button-affiliate:active {
  border: 1px solid #24b0a2 !important;
  background-color: #24b0a2 !important;
}

.modal .custom-button {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.modal .button-content {
  display: flex;
  align-items: center;
}

.modal .radio-circle {
  width: 16px;
  height: 16px;
  border: 2px solid #24b0a2;
  border-radius: 50%;
  margin-right: 10px;
  background-color: transparent;
}

.modal .radio-circle.selected {
  background-color: #24b0a2;
}

.modal .btn-primary .radio-circle {
  border-color: white;
}

.modal .btn-primary .radio-circle.selected {
  background-color: #fcba03;
}

/* end register */

/* login */

.login {
  padding-top: 10%;
}

.login h4 {
  font-weight: 700;
  color: rgb(99, 99, 99);
  margin: 0;
}

.login h6 {
  color: grey;
  font-weight: 300;
  margin-bottom: 2rem;
}

.heroImageLogin {
  width: 65%;
}

.logoImageLogin {
  width: 65%;
}

#container2 {
  padding-inline: 30px;
}

.login a {
  color: #24b0a2;
}

.login button {
  background-color: #24b0a2;
  border: 0;
  width: 100%;
  margin-bottom: 10px;
}

.login button:hover {
  background-color: #24b0a2;
  border: 0;
  width: 100%;
  margin-bottom: 10px;
}

.login button:active {
  background-color: #24b0a29b !important;
  border: 0;
  width: 100%;
  margin-bottom: 10px;
}

/* end login */

/* navigation */

.navbar {
  background-color: white;
}

.navbar .nav-link {
  color: #24b0a2;
}

.navbar .nav-link:hover {
  color: black;
}

.navbar .nav-link:active {
  color: #24b0a2 !important;
}

.navbar .nav-link:focus {
  color: #24b0a2 !important;
}

.bottom-tab-nav {
  border-top: solid 1px #ebebeb;
}

.nav-link,
.bottom-nav-link {
  color: #55575b;
}
.bottom-nav-link.active {
  color: #922c88;
}
.bottom-tab-label {
  font-size: 12px;
}

.navbar .col-sec-1 {
  display: flex !important;
  padding-inline: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
}

.navbar .col-top {
  color: white;
  border-radius: 10px;
}

.navbar .ballance::before {
  content: "Rp.";
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  vertical-align: center;
  color: black;
}

.navbar .ballance {
  font-size: 21px;
  font-weight: 700;
  padding-block: -10px;
  text-align: center;
  color: black;
}

.navbar .col-ballance {
  background-color: #f7f7f7;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.1em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 0.1em;
  border-radius: 10px;
  padding-block: 10px;
  width: fit-content;
}

/* end navigation */

/* start home */

.home {
  padding-top: 12.5rem;
  padding-bottom: 10rem;
}

.home h5 {
  font-weight: 400;
}

.home .row-fluid {
  white-space: nowrap;
}

.home .img-product {
  width: 40px;
}

.home .tabs {
  white-space: normal;
  padding-block: 15px;
  margin-right: 10px;
  border: 1px dashed #bdbdbd;
  border-radius: 10px;
}

.home .rts___tab___selected {
  white-space: normal;
  padding-block: 15px;
  border: 1px solid #24b0a2;
  border-radius: 10px;
  background-color: white;
}

.home .tabs-not-active {
  white-space: normal;
  padding-block: 15px;
  border: 1px dashed #bdbdbd;
  border-radius: 10px;
}

.home .fa-lg-2 {
  background-color: rgb(236, 236, 236);
  padding-inline: 10px;
  border-radius: 100px;
}

.home .img-lg {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
}

.home .con-product {
  padding-inline: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
}

.home .btn-payment:hover {
  border: 1px solid #24b0a2;
  background-color: #24b0a2;
}

.home .btn-payment:active {
  border: 1px solid #24b0a29b !important;
  background-color: #24b0a29b !important;
}

.home .btn-payment {
  width: 100%;
  margin-bottom: 15px;
  background-color: transparent;
  border: 1px solid #24b0a2;
  color: #24b0a2;
}

.home .con-product-2 {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
}

.home .status-txt {
  padding-inline: 5px;
  font-weight: 500;
  padding-block: 5px;
  border-radius: 10px;
  font-size: 14px;
  color: rgb(84, 151, 199);
  background-color: rgba(128, 177, 212, 0.19);
}

.home .note-txt {
  color: grey;
  font-size: 12px;
  font-weight: 400;
  margin-top: 9px;
  padding-inline: 10px;
  border: 1px solid #c9c9c97a;
  border-radius: 10px;
}

.home .btn-time:hover {
  background-color: rgba(128, 128, 128, 0.241) !important;
  border: 1px solid rgba(128, 128, 128, 0.241) !important;
}

.home .btn-time {
  border: 1px solid #24b0a2 !important;
  color: #24b0a2;
  background-color: transparent;
}

.home .btn-time:focus {
  border: 1px solid #24b0a2 !important;
  color: white;
  background-color: #24b0a2 !important;
}

.home .btn-time:active {
  border: 1px solid #24b0a29b !important;
  color: white;
  background-color: #24b0a29b !important;
}

.home h3 {
  color: rgb(92, 91, 91);
}

.home h2 {
  color: #24b0a2;
}
.home h4 {
  color: black;
  font-weight: 600;
}

.home .title {
  color: gray;
}

.home .box-menu {
  justify-content: space-between;
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.home .col-menu {
  padding: 0.3rem;
}

.home .menu-title {
  padding-inline: 0.5rem;
  padding-block: 0.5rem;
}

.home .img-menu {
  width: 100%;
  height: 8rem;
  border-radius: 10px;
  height: auto;
}

.home .tot-menu {
  justify-content: end;
}

.home .add-menu {
  padding: 3px;
  border-radius: 10px;
  text-align: center;
}

.home .qyt-menu {
  padding: 3px;
  font-weight: bold;
  text-align: center;
}
.home .sub-menu {
  color: #24b0a2 !important;
  padding: 3px;
  border-radius: 10px;
  text-align: center;
}

.home .cart-menu {
  background-color: red;
  width: 95%;
  left: 5%;
  height: 50px;
  position: fixed;
  bottom: 95px;
  z-index: 1;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #24b0a2;
  align-items: center;
  text-align: start;
}

.home .col-history {
  cursor: pointer;
}
.home .col-topup {
  cursor: pointer;
}

.home .poin {
  font-size: 35px;
  font-weight: 700;
}

.home .col-profile {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding-top: 40px;
  text-align: center;
  padding-inline: 20px;
}

.home .col-data {
  border-radius: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.qyt-variant {
  font-weight: 800;
  color: black;
}
/* end home */

/* start card */

.cards {
  padding-top: 4.3rem;
  padding-bottom: 8rem;
}
.cards .col-card {
  width: 100%;
  height: 15rem;
  border-radius: 10px;
  background-size: 100%, 20rem;
  background-position: center;
  background-repeat: no-repeat;
}

.cards .btn-add-balance {
  background-color: white;
  border: 1px solid white;
  color: #24b0a2;
  border-radius: 40px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
}

.cards .btn-add-balance:disabled {
  background-color: black;
  border: 1px solid black;
  color: white;
  border-radius: 40px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
}

.cards h2 {
  color: #24b0a2;
  font-weight: 800;
}

.cards .custom-button {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.cards .btn-rescan {
  background-color: #24b0a2;
  border-radius: 20px;
  border: 1px solid #24b0a2;
  padding-block: 7px;
}

.cards .button-content {
  display: flex;
  align-items: center;
}

.cards .radio-circle {
  width: 16px;
  height: 16px;
  border: 2px solid #24b0a2;
  border-radius: 50%;
  margin-right: 10px;
  background-color: transparent;
}

.cards .radio-circle.selected {
  background-color: #24b0a2;
}

.cards .btn-primary .radio-circle {
  border-color: white;
}

.cards .btn-primary .radio-circle.selected {
  background-color: #fcba03;
}

/* end card */
/* start profile */

.profile {
  padding-top: 4rem;
  padding-bottom: 8rem;
}

.profile .col-data-profile {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding-block: 30px;
  padding-inline: 20px;
  margin-bottom: 40px;
}

.profile .col-data-password {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding-block: 30px;
  padding-inline: 20px;
  margin-bottom: 20px;
}

.profile h5 {
  color: white;
}

.h5 {
  font-weight: 400;
  font-size: 14px;
}

.profile h6 {
  margin-bottom: 10px;
  color: gray;
}
.profile h3 {
  color: gray;
}

.profile .img-profile {
  border-radius: 10px;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-bottom: 20px;
}

.profile .tier-title {
  padding-inline: 5px;
  font-weight: 500;
  padding-block: 5px;
  border-radius: 10px;
  font-size: 14px;
  color: #24b0a2;
  background-color: #24b0a21d;
}

.profile .alert-title {
  margin-bottom: 10px;
  padding-inline: 15px;
  font-weight: 500;
  padding-block: 15px;
  border-radius: 10px;
  font-size: 14px;
  color: #24b0a2;
  background-color: #007e7c2c;
}

.profile .button-change-phone {
  background-color: #24b0a2;
  border: 1px solid #24b0a2;
  color: white;
}

.profile .button-change-phone:hover {
  background-color: #24b0a2 !important;
  border: 1px solid #24b0a2 !important;
  color: white;
}

.profile .button-change-phone:active {
  background-color: white !important;
  border: 1px solid #24b0a2 !important;
  color: #24b0a2 !important;
}

.profile .tabs {
  white-space: normal;
  padding-block: 15px;
  margin-right: 10px;
  border-radius: 10px;
}

.profile .rts___tab___selected {
  white-space: normal;
  padding-block: 15px;
  border-radius: 10px;
  background-color: white;
}

/* end prifle */

/* start pay scanner */

.payscanner {
  padding-top: 3.5rem;
  padding-bottom: 15rem;
}

.payscanner .img-menu {
  width: 100%;
  border-radius: 5px;
  height: auto;
}

.payscanner h2 {
  color: #24b0a2;
  font-weight: 800;
}

.payscanner .custom-button {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.payscanner .button-content {
  display: flex;
  align-items: center;
}

.payscanner .row-cart-menu {
  margin-bottom: 10px;
  padding-block: 10px;
  border-radius: 10px;
  height: fit-content;
  border: 1px solid #ccc;
}

.payscanner .radio-circle {
  width: 16px;
  height: 16px;
  border: 2px solid #24b0a2;
  border-radius: 50%;
  margin-right: 10px;
  background-color: transparent;
}

.payscanner .radio-circle.selected {
  background-color: #24b0a2;
}

.payscanner .btn-primary .radio-circle {
  border-color: white;
}

.payscanner .btn-primary .radio-circle.selected {
  background-color: #fcba03;
}

.payscanner .nav {
  position: fixed;
  top: 0px;
  width: 100%;
  padding-top: 10px;
  left: 0%;
  height: 3rem;
  background-color: white;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.05),
    0px -1px 0px rgba(0, 0, 0, 0.05) inset;
}

.payscanner .col-payment-detail {
  width: 100%;
  height: 20rem;
  position: fixed;
  padding-block: 20px;
  bottom: 0px;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.05),
    0px -1px 0px rgba(0, 0, 0, 0.05) inset;
  border-radius: 10px;
  background-color: white;
}

.payscanner .col-payment-detail .col-payment-detail-list {
  padding-inline: 20px;
}

.payscanner .col-payment-detail .btn-paynow {
  border-radius: 30px;
  align-items: center;
  height: 3rem;
  width: 95%;
  left: 2.5%;
  background-color: #24b0a2;
  border: 1px solid #24b0a2;
  position: fixed;
  bottom: 10px;
  z-index: 1;
  text-align: center;
  font-weight: 600;
}

/* end pay scanner */

/* start product */

.product {
  padding-top: 4rem;
  padding-bottom: 8rem;
}

.product h5 {
  color: gray;
}

.product h6 {
  font-size: 14px;
  color: gray;
}

.product h4 {
  color: rgba(88, 88, 88, 0.968);
  font-weight: 400;
}

.product .sub-title {
  font-weight: 300;
  color: gray;
}

.product .sub-title {
  font-weight: 300;
  color: gray;
}

.product h3 {
  color: gray;
}

.product .tabs {
  white-space: normal;
  padding-block: 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px dashed #bdbdbd;
  border-radius: 10px;
}

.product .rts___tab___selected {
  white-space: normal;
  padding-block: 15px;
  border: 1px solid #24b0a2;
  border-radius: 10px;
  background-color: white;
}

.product .tabs-not-active {
  white-space: normal;
  padding-block: 15px;
  border: 1px dashed #bdbdbd;
  border-radius: 10px;
}

.product .img-product {
  width: 40px;
}

.product .product-list {
  margin-bottom: 15px;
}

.product .img-list {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
}

.product .product-detail {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
}

.product .product-price {
  color: #24b0a2;
  font-weight: 600;
}

.product .product-price::before {
  content: "Rp ";
  font-weight: 400;
  font-size: 15px;
  vertical-align: top;
}

.product .btn-list {
  width: 100%;
  margin-bottom: -10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: #24b0a2;
  border: 1px solid #24b0a2;
}

.product .btn-list:hover {
  width: 100%;
  border: 1px solid #24b0a2;
  background-color: #24b0a2;
}

.product .btn-list:active {
  width: 100%;
  border: 1px solid #24b0a2 !important;
  background-color: #24b0a2 !important;
}

.product .btn-list:focus {
  width: 100%;
  border: 1px solid #24b0a2 !important;
  background-color: #24b0a2 !important;
}

.product .product-banner {
  margin-bottom: 40px;
  color: white;
  padding-inline: 30px;
  border-radius: 10px;
  background-color: #24b0a22e;
  padding-block: 30px;
}

.product .product-banner-title {
  color: #24b0a2;
  font-weight: 600;
  margin-bottom: 1px;
}

.product .product-banner-sub-title {
  color: #24b0a2;
  font-weight: 300;
}

.product .modal-custom-purchase {
  background-color: red;
}

/* end product */

/* start notification */

.notification {
  padding-top: 4rem;
  padding-bottom: 8rem;
}

.notification .nav-link {
  color: #24b0a2;
}

.notification .nav-link:focus {
  color: #1f412b !important;
}

.notification .nav-link:hover {
  color: #1f412b !important;
}

/* start not found */

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  font-family: "Arial", sans-serif;
}

.not-found-title {
  font-size: 10rem;
  margin: 0;
  color: #24b0a2;
}

.not-found-text {
  font-size: 1.5rem;
  color: #2d3436;
  margin: 1rem 0;
}

.not-found-button {
  padding: 0.75rem 1.5rem;
  color: #fff;
  background-color: #24b0a2;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.not-found-button:hover {
  background-color: #24b0a2;
}

.not-found-animation {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 200px;
  margin-top: 2rem;
}

.not-found-cloud {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: moveClouds 15s linear infinite;
}

.not-found-cloud:nth-child(2) {
  width: 100px;
  height: 100px;
  top: 50px;
  left: 50%;
  animation-duration: 18s;
}

@keyframes moveClouds {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}

/* end not found */

/* Start media query */

/* Small */
@media (max-width: 768px) {
  .navbar {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
  .login {
    padding-top: 35%;
  }
  .heroImageLogin {
    content-visibility: hidden;
  }
  #container2 {
    padding-inline: 20px;
  }
  .home .ballance {
    font-size: 25px;
    font-weight: 700;
  }
  .home .poin {
    font-size: 25px;
    font-weight: 700;
  }
  .navbar {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }

  .home .img-lg {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .home .tabs {
    padding-inline: 25px;
  }

  .home .rts___tab___selected {
    padding-inline: 25px;
  }

  .home .tabs-not-active {
    padding-inline: 25px;
  }
  .product .tabs {
    padding-inline: 25px;
  }

  .product .rts___tab___selected {
    padding-inline: 25px;
  }

  .product .tabs-not-active {
    padding-inline: 25px;
  }

  video {
    width: 100%;
    transform: scaleX(-1);
  }
}

/* medium */

@media (min-width: 768px) {
  .navbar {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }

  .home .menu {
    padding-block: 1rem;
  }

  video {
    width: 100%;
    transform: scaleX(-1);
  }
}

@media (max-width: 992px) {
  .navbar {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
  .home .tabs {
    padding-inline: 15px;
  }

  .home .menu {
    padding-block: 1rem;
  }

  .home .rts___tab___selected {
    padding-inline: 15px;
  }

  .home .tabs-not-active {
    padding-inline: 15px;
  }
  .product .tabs {
    padding-inline: 25px;
  }
  .product .rts___tab___selected {
    padding-inline: 25px;
  }
  .product .tabs-not-active {
    padding-inline: 25px;
  }
  video {
    width: 100%;
    transform: scaleX(-1);
  }
}

/* Large */

@media (min-width: 1198px) {
  .navbar {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
  .home .tabs {
    padding-inline: 15px;
  }

  .home .menu {
    padding-inline: 10rem;
    padding-block: 3rem;
  }

  .home .rts___tab___selected {
    padding-inline: 15px;
  }

  .home .tabs-not-active {
    padding-inline: 15px;
  }
  .product .tabs {
    padding-inline: 25px;
  }
  .product .rts___tab___selected {
    padding-inline: 25px;
  }
  .product .tabs-not-active {
    padding-inline: 25px;
  }
  video {
    width: 50%;
    transform: scaleX(-1);
  }
}
